























import { Component, Vue, Emit } from "vue-property-decorator";

@Component
export default class ZipInput extends Vue {
  private zipCode: string | null = null;

  formSubmit(event: Event): void {
    event.preventDefault();
    if (this.validZipCode) {
      this.onFormSubmit();
    }
  }

  @Emit()
  onFormSubmit(): string | undefined {
    if (this.zipCode && this.validZipCode) {
      return this.zipCode;
    }
  }

  get validZipCode(): boolean | null {
    if (this.zipCode) {
      return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(this.zipCode);
    }
    return null;
  }
}
