
























import { Component, Vue } from "vue-property-decorator";
// import HelloWorld from "./components/HelloWorld.vue";
import NavBar from "./components/NavBar.vue";
import ZipInput from "./components/ZipInput.vue";
import LoadingSpinner from "./components/LoadingSpinner.vue";
import { IShownWeatherData, WeatherService } from "./services/weather";

@Component({
  components: {
    NavBar,
    ZipInput,
    LoadingSpinner,
  },
})
export default class App extends Vue {
  private loading = false;
  private weatherData: IShownWeatherData[] | null = null;

  async zipFormSubmit(e: string): Promise<void> {
    this.loading = true;
    this.weatherData = await WeatherService.hourlyQuery(e);
    this.loading = false;
  }
}
